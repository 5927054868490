import React from "react";
import Entry from "./Entry";
import emojipedia from "../emojipedia";

function dictionary(emojipedia){
      return(
      <Entry
      key={emojipedia.id}
      emoji={emojipedia.emoji}
      name={emojipedia.name}
      meaning={emojipedia.meaning}
      />)

}

function App() {
  return (
    <div>
      <h1>
        <span>Emojipedia</span>
      </h1>

      <dl className="dictionary ">
            {emojipedia.map(dictionary)}
      </dl>
      <footer>
            <h3 className="footer">Design By- Vipin khandelwal</h3>
      </footer>
    </div>
  );
}

export default App;
