const emojipedia = [
  {
    id: 1,
    emoji: "💪",
    name: "Tense Biceps",
    meaning:
      "“You can do that!” or “I feel strong!” Arm with tense biceps. Also used in connection with doing sports, e.g. at the gym."
  },
  {
    id: 2,
    emoji: "🙏",
    name: "Person With Folded Hands",
    meaning:
      "Two hands pressed together. Is currently very introverted, saying a prayer, or hoping for enlightenment. Is also used as a “high five” or to say thank you."
  },
  {
    id: 3,
    emoji: "🤣",
    name: "Rolling On The Floor, Laughing",
    meaning:
      "This is funny! A smiley face, rolling on the floor, laughing. The face is laughing boundlessly. The emoji version of “rofl“. Stands for „rolling on the floor, laughing“."
  },
  {
    id: 4,
    emoji: "💘",
    name: "Heart with Arrow",
    meaning:
      "A heart emoji with an arrow through the center, like the arrow that cupid shoots for love."
  },
  {
    id: 5,
    emoji: "🏠",
    name: "House",
    meaning:
      "A house, otherwise known as a home when someone is living in it. Suitable for a couple or larger family, other perhaps housemates."
  },
  {
    id: 6,
    emoji: "🚓",
    name: " Police Car",
    meaning:
      "A police car, shown with an emergency light on the top.This emoji is displayed from the side, as opposed to the oncoming police car emoji, which is shown from the front."
  },
  {
    id: 7,
    emoji: "😊",
    name: "Smiling Face",
    meaning:
      "A yellow face with smiling eyes and a broad, closed smile turning up to rosy cheeks. Often expresses genuine happiness and warm, positive feelings."
  },
  {
    id: 8,
    emoji: "🏄‍♂️",
    name: "Man Surfing",
    meaning:
      "The male version of the 🏄 Surfer emoji. Currently identical in appearance to the non-gendered base emoji."
  },
  {
    id: 9,
    emoji: "🍎️",
    name: "Red Apple",
    meaning:
      "A classic red apple, shown with a stem and single, green leaf on major platforms. Commonly associated with doctors, teachers, and New York."
  }

];
export default emojipedia;
